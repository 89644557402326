// src/components/Navbar.tsx

import React, { useState, useCallback, useContext } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Fade,
  useScrollTrigger,
  styled,
  alpha,
  MenuItem,
  useMediaQuery,
  Button,
  ButtonProps,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as AboutIcon,
  ContactMail as ContactIcon,
  Login as LoginIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Language as LanguageIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';

// Adjusted import
import SvgLogo from '../assets/Medicup.svg'; // Ensure the path is correct

import { ColorModeContext } from '../pages/_app';
import { useTheme } from '@mui/material/styles';

// Styled components
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  backdropFilter: 'blur(8px)',
  boxShadow: theme.shadows[4],
  height: 'auto',
  transition: theme.transitions.create(['background', 'box-shadow'], {
    duration: theme.transitions.duration.standard,
  }),
}));

const LogoWrapper = styled(motion.div)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height: 'auto',
  maxWidth: 100,
  [theme.breakpoints.up('md')]: {
    maxWidth: 130,
  },
}));

interface StyledNavButtonProps extends ButtonProps {
  active: boolean;
}

const StyledNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<StyledNavButtonProps>(({ theme, active }) => ({
  margin: theme.spacing(0, 1),
  padding: theme.spacing(0.5, 1),
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '0.85rem',
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  minWidth: 'auto',
  '&:hover': {
    boxShadow: theme.shadows[4],
    transform: 'translateY(-1px)',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

interface NavbarProps {
  lng: string;
}

const Navbar: React.FC<NavbarProps> = ({ lng }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [languageMenu, setLanguageMenu] = useState<null | HTMLElement>(null);

  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const router = useRouter();
  const { t } = useTranslation('common');
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 100 });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navItems = [
    { href: '/', label: t('Home'), icon: <HomeIcon /> },
    { href: '/about', label: t('About'), icon: <AboutIcon /> },
    { href: '/contact', label: t('Contact'), icon: <ContactIcon /> },
  ];

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);

  const handleLanguageMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setLanguageMenu(event.currentTarget);
  }, []);

  const handleLanguageClose = useCallback(() => {
    setLanguageMenu(null);
  }, []);

  const handleLogoClick = useCallback(() => {
    router.push('/');
  }, [router]);

  const renderLogo = (
    <LogoWrapper
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleLogoClick}
    >
      <SvgLogo width={100} height="auto" /> {/* Adjusted usage */}
    </LogoWrapper>
  );

  const renderDesktopNav = (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        ml: 1,
      }}
    >
      {navItems.map((item) => (
        <Link key={item.href} href={item.href} passHref legacyBehavior>
          <StyledNavButton
            active={router.pathname === item.href}
            startIcon={
              React.cloneElement(item.icon, {
                style: { fontSize: '1rem' },
              })
            }
            component="a"
          >
            {item.label}
          </StyledNavButton>
        </Link>
      ))}
      <Link href="/login" passHref legacyBehavior>
        <StyledNavButton
          active={router.pathname === '/login'}
          startIcon={<LoginIcon style={{ fontSize: '1rem' }} />}
          component="a"
        >
          {t('login')}
        </StyledNavButton>
      </Link>
    </Box>
  );

  const renderMobileDrawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': {
          width: 220,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ mb: 1, color: theme.palette.text.primary }}
        >
          <CloseIcon />
        </IconButton>
        <List>
          {navItems.map((item) => (
            <Link key={item.href} href={item.href} passHref legacyBehavior>
              <ListItemButton
                onClick={handleDrawerToggle}
                selected={router.pathname === item.href}
                sx={{
                  borderRadius: 2,
                  mb: 0.5,
                  '&.Mui-selected': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  },
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                }}
                component="a"
              >
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    style: { color: theme.palette.text.primary },
                  }}
                />
              </ListItemButton>
            </Link>
          ))}
          <Link href="/login" passHref legacyBehavior>
            <ListItemButton
              onClick={handleDrawerToggle}
              selected={router.pathname === '/login'}
              sx={{
                borderRadius: 2,
                '&.Mui-selected': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                },
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },
              }}
              component="a"
            >
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('login')}
                primaryTypographyProps={{
                  style: { color: theme.palette.text.primary },
                }}
              />
            </ListItemButton>
          </Link>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <>
      <StyledAppBar position="fixed" elevation={trigger ? 4 : 0}>
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: (theme) => theme.spacing(0, 1),
              minHeight: { xs: 56, sm: 64 },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isMobile && (
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 0.5, color: theme.palette.text.primary }}
                  size="small"
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
              )}
              {renderLogo}
            </Box>
            {!isMobile && renderDesktopNav}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={colorMode.toggleColorMode}
                sx={{ ml: 0.5, color: theme.palette.text.primary }}
                size="small"
              >
                {theme.palette.mode === 'dark' ? (
                  <Brightness7Icon fontSize="inherit" />
                ) : (
                  <Brightness4Icon fontSize="inherit" />
                )}
              </IconButton>

              <IconButton
                onClick={handleLanguageMenu}
                aria-label="language"
                aria-controls="language-menu"
                aria-haspopup="true"
                size="small"
                sx={{ color: theme.palette.text.primary }}
              >
                <LanguageIcon fontSize="inherit" />
                <ExpandMoreIcon fontSize="inherit" />
              </IconButton>

              <Menu
                id="language-menu"
                anchorEl={languageMenu}
                open={Boolean(languageMenu)}
                onClose={handleLanguageClose}
                TransitionComponent={Fade}
                sx={{
                  '& .MuiPaper-root': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  },
                }}
                MenuListProps={{
                  dense: true,
                }}
              >
                <MenuItem onClick={handleLanguageClose}>English</MenuItem>
                <MenuItem onClick={handleLanguageClose}>Español</MenuItem>
                <MenuItem onClick={handleLanguageClose}>Français</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
      {renderMobileDrawer}

      {/* Spacer for the AppBar */}
      <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }} />
    </>
  );
};

export default Navbar;